import { Semantic } from 'packages/components'
import { Button } from 'packages/components/buttons'
import { Checkbox, FormInput, FormToggle } from 'packages/components/inputs'
import { Modal } from 'packages/components/modal'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
// import { NewFeatureBudge } from "packages/components/newFeatureBudge/nodes";
import {
  DescriptionLabel,
  DescriptionWrapper,
  StyledTextareaContainer,
} from '@configurator/components/modals/albumCreate/nodes'
import {
  Content,
  Description,
  Header,
  MobileSubmitContainer,
  SmallTitle,
  StDividedField,
  StyledInput,
} from '@configurator/components/modals/nodes'
import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'
import TextEditorContainer from '@configurator/containers/TextEditorContainer'
import { useSubscription } from '@configurator/providers/subscription'
import { openErrorModal } from '@configurator/redux/modules/subscription/actions'
import { get } from 'lodash'
import isEqual from 'lodash/isEqual'
import union from 'lodash/union'
import Message from 'packages/components/message/Message'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { WEBSITE_BLOCK } from 'packages/enum'
import { SPACES_REGEXP } from 'packages/helpers/Helper'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { useCallback, useMemo, useState } from 'react'
import { DisplayAlbumMode } from '../common/DisplayAlbumMode'
import { useUploadImage } from '../common/hooks/useUploadImage'
import ModalFooterButtons from '../common/modalFooterButtons/modalFooterButtons'
import ModalTabs from '../common/modalTabs/modalTabs'
import SeoSettingsTab from '../pageUpdate/seoSettingsTab'
import { CheckboxContainer, PagesList, StyledSlug, StyledSlugPreview } from './nodes'

const modalStyle = {
  width: '620px',
  height: 'initial',
}

const tabs = [
  {
    text: 'newDesign.album.update.tabs.main',
    intercomAttribute: 'AlbumTabSettings',
  },
  {
    text: 'newDesign.album.update.tabs.seo',
    intercomAttribute: 'AlbumTabSEO',
  },
  {
    text: 'newDesign.page.update.tabs.displayMode',
    intercomAttribute: 'AlbumTabDisplayMode',
  },
]

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const AlbumUpdate = (
  {
    open,
    onClose,
    handleSubmit,
    errors,
    initErrors,
    status,
    isSubmitting,
    loading,
    pagesList,
    setFieldValue,
    initialValues,
    values,
    // onDelete,
    openDiscardChangesModal,
    closeDiscardChangesModal,
    loadingDeleteAlbum,
    premiumDomain,
    domain,
    title,
    seoPhotoUrl,
    seoDescription,
    pageId,
    lockModalProgress,
    updateBlockProperty,
    unLockModalProgress,
    pageData,
    meta,
  },
  { intl: { formatMessage } }
) => {
  errors = initErrors || errors
  const [currentSeoPhotoUrl, setCurrentSeoPhotoUrl] = useState(seoPhotoUrl)
  useEffect(() => {
    setCurrentSeoPhotoUrl(seoPhotoUrl)
  }, [seoPhotoUrl])
  const prevValues = usePrevious(values)
  const { permissions } = useSubscription()
  const [activeTab, setActiveTab] = useState(0)

  const {
    handleDeletePreviewFile: handleDeleteSeoPreviewFile,
    handleDropFile: handleDropSeoFile,
    isFileReady: isSeoFileReady,
    preview: seoPreview,
  } = useUploadImage(setFieldValue, 'seoImage')

  const isV2Template = meta.templateName?.includes('v2')

  const deleteSeoPhotoUrl = () => {
    if (get(seoPreview, 'preview', '')) {
      handleDeleteSeoPreviewFile()
    } else {
      setFieldValue('deleteSeoPhotoUrl', true)
      setCurrentSeoPhotoUrl('')
    }
  }

  const undoDeleteSeoPhotoUrlOnDropFile = (files) => {
    handleDropSeoFile(files)
    setFieldValue('deleteSeoPhotoUrl', false)
  }

  const handleChangeTab = useCallback(
    (idx) => {
      setActiveTab(idx)
    },
    [setActiveTab]
  )

  useEffect(() => {
    if (prevValues && prevValues.visible !== values.visible) {
      setFieldValue('hide', !values.visible)
    }
    if (prevValues && prevValues.hide !== values.hide) {
      setFieldValue('visible', !values.hide)
    }
  }, [prevValues, setFieldValue, values])

  const error = Object.keys(errors).length || (status && !!status.error)

  const handleChangeText = React.useCallback(
    (text) => {
      setFieldValue('description', text)
    },
    [setFieldValue]
  )

  const handleChangePage = (pageId) => {
    const newPageIds = isPageChecked(pageId)
      ? values.pageIds.filter((el) => el !== pageId)
      : union(values.pageIds, [pageId])

    setFieldValue('pageIds', newPageIds)
  }

  const isPageChecked = (pageId) => !!values.pageIds.find((el) => el === pageId)

  const slugData = useMemo(() => {
    return decodeURIComponent(pageId)
      .split('/')
      .filter((s) => s && s !== '/')
  }, [pageId])

  const parentPageUrl =
    pagesList.find((p) => p.id === pageId)?.url || pagesList.find((p) => p.url === slugData[0])?.url

  const renderPagesList = () => {
    return (
      <PagesList>
        <SmallTitle>
          {formatMessage({
            id: 'newDesign.album.update.field.displayCategory',
          })}
          {/* <NewFeatureBudge>new</NewFeatureBudge> */}
        </SmallTitle>
        <div>
          {(pagesList || [])
            .sort((a, b) => {
              if (a.mainNavigation === b.mainNavigation) {
                return 0
              }
              if (a.mainNavigation) {
                return -1
              }
              return 1
            })
            .map((page) => (
              <CheckboxContainer key={page.id}>
                <Checkbox
                  onChange={() => handleChangePage(page.id)}
                  checked={isPageChecked(page.id)}
                />
                <span>{page.title}</span>
              </CheckboxContainer>
            ))}
        </div>
      </PagesList>
    )
  }

  const handleClose = () => {
    if (!isEqual(values, initialValues)) {
      openDiscardChangesModal({
        subMessageId: 'discardChanges.subMessage',
        yesMessageId: 'discardChanges.yesMessage',
        hideHeader: true,
        onClickYes: () => {
          onClose()
          closeDiscardChangesModal()
        },
        styles: { width: '450px' },
      })
    } else {
      onClose()
    }
  }

  const onDropRejected = (files, accept) => {
    if (files.find((el) => el.size > MAX_IMAGE_SIZE)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: MAX_IMAGE_SIZE / 1024 / 1024 },
        hideCancelButton: true,
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
        hideCancelButton: true,
      })
    }
  }

  const userDomain = `https://${premiumDomain || `${domain}.vsble.me`}`

  const renderContent = () => {
    if (activeTab === 0) {
      return (
        <>
          <StyledInput>
            <FormInput name='title' type='text' label='newDesign.album.update.field.name' />
          </StyledInput>
          <StyledInput>
            <FormInput
              name='albumUrl'
              type='text'
              label='newDesign.album.update.field.slug'
              data-intercom-target='AlbumSlug'
            />
            <StyledSlugPreview>
              {domain &&
                `https://${premiumDomain || `${domain}.vsble.me`}/${parentPageUrl + '/' || ''}`}
              <StyledSlug>{values.albumUrl?.replace(SPACES_REGEXP, '-')}</StyledSlug>
            </StyledSlugPreview>
          </StyledInput>
          <StyledTextareaContainer>
            <DescriptionWrapper>
              <DescriptionLabel>
                {formatMessage({
                  id: 'newDesign.album.update.field.desc',
                })}
              </DescriptionLabel>
              <TextEditorContainer
                text={initialValues.description}
                handleChangeText={handleChangeText}
                inline
              />
            </DescriptionWrapper>
          </StyledTextareaContainer>
          <StDividedField>{renderPagesList()}</StDividedField>
          <StDividedField>
            <div>
              <SmallTitle>
                {formatMessage({
                  id: 'newDesign.album.update.toggle.visibility.title',
                })}
              </SmallTitle>
              <Description>
                {formatMessage({
                  id: 'newDesign.album.update.toggle.visibility.desc',
                })}
              </Description>
            </div>
            <FormToggle name='hide' />
          </StDividedField>
          <StDividedField>
            <div>
              <SmallTitle>
                {formatMessage({
                  id: 'newDesign.album.update.toggle.hideHeader.title',
                })}
              </SmallTitle>
              <Description>
                {formatMessage({
                  id: 'newDesign.album.update.toggle.hideHeader.desc',
                })}
              </Description>
            </div>
            <div>
              <PermissionsOverlay isAllowed={permissions.HIDE_PAGE_TITLE}>
                <FormToggle name='hideHeader' />
              </PermissionsOverlay>
            </div>
          </StDividedField>
        </>
      )
    }

    if (activeTab === 1) {
      return (
        <SeoSettingsTab
          isAlbum={true}
          values={values}
          isSubmitting={isSubmitting}
          onDropSeoFile={undoDeleteSeoPhotoUrlOnDropFile}
          onRejectSeoFile={onDropRejected}
          isFileReady={isSeoFileReady}
          fileSrc={get(seoPreview, 'preview', '')}
          fileName={get(seoPreview, 'file.name', '')}
          onDeleteImagePreview={deleteSeoPhotoUrl}
          domain={userDomain}
          pageTitle={title}
          seoPhotoUrl={currentSeoPhotoUrl}
          seoDescription={seoDescription}
        />
      )
    }

    if (isV2Template && activeTab === 2) {
      const isAlbumList = (pageData.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)

      return isAlbumList ? (
        <DisplayAlbumMode
          isShowNotice
          values={pageData}
          onClose={onClose}
          lockModalProgress={lockModalProgress}
          unLockModalProgress={unLockModalProgress}
          updateBlockProperty={updateBlockProperty}
        />
      ) : null
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      styles={modalStyle}
      mobileWide
      variant='gray'
      showHeaderCloseIcon
      showCloseIcon={false}
    >
      <Content>
        <Semantic.Form
          noValidate
          error={error}
          loading={isSubmitting || loading || loadingDeleteAlbum}
          onSubmit={handleSubmit}
        >
          <Header>
            <span>
              {formatMessage({
                id: 'newDesign.album.update.header',
              })}
            </span>
          </Header>
          <ModalTabs tabs={tabs} onTabClick={handleChangeTab} activeIdx={activeTab} />
          <Message error={error} messages={errors} content={status && status.error} />
          {renderContent()}
          {activeTab !== 2 && (
            <MobileSubmitContainer>
              <Button
                type='submit'
                fluid
                content={formatMessage({
                  id: 'newDesign.album.update.submit',
                })}
                disabled={isSubmitting}
              />
            </MobileSubmitContainer>
          )}
          {/*<DeleteItem onClick={onDelete}>*/}
          {/*  {formatMessage({*/}
          {/*    id: 'newDesign.album.update.button.deleteAlbum',*/}
          {/*  })}*/}
          {/*</DeleteItem>*/}
          {activeTab !== 2 && <ModalFooterButtons disabled={isSubmitting} onClose={onClose} />}
        </Semantic.Form>
      </Content>
    </Modal>
  )
}

AlbumUpdate.propTypes = {
  albumId: PropTypes.number.isRequired,
  pageId: PropTypes.string,
  pageType: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  pagesList: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  // onDelete: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  loadingDeleteAlbum: PropTypes.bool,
  seoPhotoUrl: PropTypes.string,
  domain: PropTypes.string,
  premiumDomain: PropTypes.string,
  lockModalProgress: PropTypes.func,
  updateBlockProperty: PropTypes.func,
  unLockModalProgress: PropTypes.func,
  pageData: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  ...formProptypes,
}

AlbumUpdate.defaultProps = {
  open: false,
  loading: false,
  loadingDeleteAlbum: false,
  pagesList: [],
  onDelete: () => {},
}

AlbumUpdate.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default AlbumUpdate
