import { Button } from 'packages/components/buttons'
import { Toggle } from 'packages/components/inputs'
import { media } from 'packages/components/media'
import { Modal } from 'packages/components/modal'
import styled, { css } from 'styled-components'

export const ModalContainer = styled(Modal)`
  position: initial !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  background-color: #f6f6f6 !important;
  border-radius: 24px !important;
  padding-bottom: 24px !important;
  text-align: left;
  color: #242426;
  position: relative !important;

  > .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    height: 24px;
    padding: 5px;
    z-index: 1;

    > svg path {
      fill: #666a73;
    }

    ${media.max.laptop`
      top: 28px;
      right: 28px;

      > svg  {
        height: 14px !important;
        width: 14px !important;
      }
    `}

    ${media.max.laptop`
      top: 16px;
      right: 16px;

      > svg  {
        height: 18px !important;
        width: 18px !important;
      }
    `}
  }

  ${media.max.laptop`
    margin: 20px 20px 20px !important;
    max-width: calc(100% - 40px) !important;
    height: calc(100vh - 40px) !important;
  `}

  ${media.max.phone`
    min-width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    margin: 0 !important;
    max-width: 100% !important;
  `}
`

export const Header = styled.h3`
  margin: 0;
  text-align: center;
  font-size: 36px;
  margin-bottom: 8px;
`

export const Description = styled.p`
  text-align: center;
  font-size: 15px;
  margin-bottom: 50px;
  color: #666a73;
  max-width: 600px;
  margin: 0 auto 24px;
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 25px;

  ${media.max.laptop`
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 0;
    }
  `}
`

export const StyledToggle = styled(Toggle)`
  margin: 0 16px;

  & > label {
    margin-top: 4px;

    &::before {
      background-color: #58c367 !important;
    }
  }
`

export const Step = styled.div`
  flex: 1;
  height: 100%;
`

export const TextGray = styled.div`
  margin-top: 24px;
  color: #666a73;
  text-align: center;
`

export const StepTitle = styled.h5`
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: 20px;

  ${media.max.phone`
        flex-direction: column;
    `}
`

export const StepContent = styled.div`
  height: 100%;

  ${media.max.phone`
        height: auto;
    `}
`

export const PlanSelectors = styled.div`
  margin: 14px 0 0 -4px;
  display: flex;
  gap: 8px;
  & > * {
    margin: 0px 0 0 4px;
  }

  ${media.max.laptop`
        flex-direction: column;
    `}
`

export const PlanSelector = styled.div`
  display: ${({ isLight }) => (isLight ? `none` : `flex`)};
  min-height: 680px;
  padding: 24px;
  font-size: 13px;
  border-radius: 12px;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  background-color: #fff;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: linear-gradient(180deg, rgba(0, 255, 127, 0.2) 0%, white 30%);
    border: 1px solid #58c367;
  }

  ${(props) =>
    props.selected &&
    css`
      background: linear-gradient(180deg, rgba(0, 255, 127, 0.2) 0%, white 30%);
      border: 1px solid #58c367;
    `}
`

export const PlanSelectorWrapper = styled.div`
  min-height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const PlanSelectorHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const IntervalSelectors = styled.div`
  gap: 8px;
  display: flex;
  width: 100%;
  margin-top: 16px;

  ${media.max.phone`
        flex-direction: column;
    `}
`

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 24px;

  ${media.max.tablet`
     margin-bottom: 16px;
  `}
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  background: rgb(235 235 237);
  height: 44px;
  border-radius: 40px;
  padding: 4px;
  width: fit-content;
  position: relative;
  cursor: pointer;
`

export const ToggleOption = styled.span`
  height: 36px;
  padding: 12px 16px 10px;
  text-align: center;
  font-size: 16px;
  color: ${({ active }) => (active ? 'black' : '#666A73')};
  cursor: pointer;
  z-index: 2;
  transition: color 0.3s ease;

  ${(props) =>
    !props.active &&
    css`
      height: 36px;
      background: white;
      border-radius: 40px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    `}
`

export const SavingsText = styled.span`
  color: #58c367;
  font-size: 16px;
  margin-left: 8px;
`

export const PriceDropdownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${media.max.tablet`
    display: none;
  `}
`

export const PriceDropdownMobileContainer = styled.div`
  display: none;

  ${media.max.tablet`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  `}
`

export const IntervalSelector = styled.div`
  display: flex;
  height: 80px;
  padding: 12px 12px 12px 16px;
  font-size: 16px;
  background-color: #f6f6f6;
  border-radius: 6px;
  cursor: pointer;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  flex: 1;
  border: 3px solid ${({ selected }) => (selected ? '#58c367' : 'transparent')};
  ${({ disabled }) => disabled && `color: #9fa1a6; pointer-events: none;`};

  b {
    font-size: 16px;
  }
`

export const IntervalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const IntervalRenewsInfo = styled.p`
  font-size: 12px;
`

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ui.radio {
    width: 24px !important;
    height: 24px !important;
    label {
      position: relative;
      &::before {
        width: 24px !important;
        height: 24px !important;
      }
      &:after {
        display: none;
      }
    }
  }
`

export const SelectorLabel = styled.label`
  font-weight: bold;
  cursor: pointer;
  flex: 100%;
  font-size: 24px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

export const Badge = styled.span`
  color: #fff;
  background-color: #242426;
  padding: 5px 8px 3px;
  border-radius: 24px;
  font-size: 13px;
  font-weight: 400;
`

export const PlanTypeDescription = styled.p`
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
`

export const RadioWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:after {
    background-color: #58c367;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    max-height: 24px;
    max-width: 24px;
    border-radius: 50%;
    content: '';
    z-index: 1;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transform: scale(${({ selected }) => (selected ? 1 : 0)});
    transition: transform 0.2s ease-in-out;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px !important;
    height: 12px !important;
    z-index: 2;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
  }
`

export const PaymentContainer = styled.div`
  display: flex;
  font-size: 16px;

  & b {
    display: flex;
    gap: 4px;
    align-items: flex-start;
  }

  & b,
  & span {
    white-space: nowrap;
  }

  & span {
    font-size: 36px;
  }

  & p {
    margin-top: -2px;
    font-size: 13px;
  }

  ${media.max.phone`
        flex-direction: column;
    `}
`

export const TotalContainer = styled.div`
  display: flex;
  margin-top: 20px;

  & > * {
    width: 45%;
  }

  ${media.max.phone`
        & > *:first-child {
            width: 65%;
        }
    `}
`

export const BottomPlanInfoContainer = styled.div`
  display: flex;
  margin-top: 8px;

  & > * {
    width: 45%;
  }

  @media (max-width: 480px) {
    & > *:first-child {
      display: none;
    }

    & > * {
      width: 100%;
    }
  }
`

export const BottomPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;

  & > * {
    margin-top: 8px;
    color: #666a73;
  }
`

export const StyledButton = styled(Button)`
  ${(props) =>
    !props.active &&
    css`
      cursor: not-allowed !important;
      pointer-events: none !important;
    `}

  height: 45px;
  font-weight: 500 !important;
  font-size: 15px !important;
  ${({ fullWidth }) => fullWidth && `width: 100%`}
`

export const ButtonsContainer = styled.div`
  & > *:not(:first-child) {
    margin-top: 16px;
  }
`

export const FeaturesTitle = styled.div`
  color: #242426;
  margin-bottom: 10px;
  font-weight: 700;
`

export const FeatureList = styled.ul`
  li {
    svg {
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      margin-bottom: 2px;
    }

    list-style: none;
    color: #666a73;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 20px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  & > p {
    width: 45%;
    margin: 0;
    padding-right: 20px;
  }

  & > ${StyledButton} {
    width: 55%;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    & > p {
      padding: 0 0 10px 0;
    }

    & > p,
    & > ${StyledButton} {
      width: 100%;
    }
  }
`

export const DowngradeContainer = styled.div`
  margin: 30px 0;

  > span {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    > span {
      font-size: 13px;
      line-height: 170%;
    }
  }

  .ui.button {
    width: 100%;
    height: 45px;
    margin-top: 30px !important;
  }
`

export const SplitDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;

  > span {
    color: #666a73;
    font-size: 13px;
    line-height: 140%;
    margin: 0 10px;
  }

  > div {
    flex: 1;
    border-top: 1px solid #e1e2e6;
  }
`

export const SavedForYear = styled.span`
  font-weight: bold;
  color: #fff;
  background-color: #00c2ff;
  font-size: 10px;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
`
